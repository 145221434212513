import React, { useEffect } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { connect } from "react-redux"
import toast from "react-hot-toast"
import {
  existOperation,
  existDevelopment,
  getMessageWhatsapp,
  getPhonesText,
} from "../helpers/helper.rendering"
import { customShulbiusTag } from "../helpers/helper.developments"
import { newSletterTokko, updateFields } from "../redux/contactDucks"
import {
  GET_INVESTOR_ACTION,
  GET_CONFIGURATIONS_ACTION,
} from "../redux/settingsDucks"
import { Helmet } from "react-helmet"
import BellagambaPng from "../images/BELLAGAMBA_grey.png"
import IconBusinessmanSvg from "../images/svg/icon-businessman.svg"
import IconDiamondSvg from "../images/svg/icon-diamond.svg"
import FixedFooterbar from "./fixed-footerbar"
import ImageRight from "../images/BPT.png"
import { useLocation } from "@reach/router"

const Footer = ({
  filters,
  api_key,
  developments,
  development,
  dispatch,
  modules,
}) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const isShulbiusPage = location.pathname.includes("/shulbius")
  const isPeliculaPage = location.pathname.includes("/pelicula")
  const isResultsPage =
    location.pathname.includes("/venta") ||
    location.pathname.includes("/alquiler")

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        keys {
          captcha
        }
        social {
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office {
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
            title_contact
            title_social
            title_newsletter
            title_office
            button_contact {
              link
              value
            }
          }
        }
      }
    }
  `)

  const [disabled, setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  })

  const handleInputChange = e => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    })
  }

  const sendForm = e => {
    e.preventDefault()
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset()
    return toast.success("Te suscribiste a nuestro newsletter correctamente.")
  }

  // Función que devuelve el año actual
  const getCurrentYear = () => {
    const date = new Date().getFullYear()
    return date
  }

  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos))
    }
    update()
    if (!realEstate?.keys?.captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [datos])

  useEffect(() => {
    dispatch(GET_INVESTOR_ACTION())
    dispatch(GET_CONFIGURATIONS_ACTION())
  }, [])

  const INVESTOR_CONFIGURATION = modules.find(
    config => config.module === "INVESTOR"
  )
  const OWNER_CONFIGURATION = modules.find(config => config.module === "OWNER")
  const configUrl = url => (url?.includes("http") ? url : `https:${url}`)

  const accesosPrivadosSection = () => {
    return (
      <div class="row item-contact">
        <div class="col-xl-4 col-lg-5">
          <h5>Acceso privado</h5>
          {/* <a class="d-flex btn" href="../cms" target="_blank">
                      ASESORES BELLAGAMBA
                      <IconBusinessmanSvg class="icon"/>
                    </a> */}
          {OWNER_CONFIGURATION?.module ? (
            <a
              class="d-flex btn"
              target="_blank"
              href={configUrl(OWNER_CONFIGURATION.url_login)}
            >
              {OWNER_CONFIGURATION.text_cta || "PROPIETARIOS"}
              <i className="icon-house icon"></i>
            </a>
          ) : null}
          {INVESTOR_CONFIGURATION?.module ? (
            <a
              class="d-flex btn btn-shulbius"
              target="_blank"
              href={configUrl(INVESTOR_CONFIGURATION.url_login)}
            >
              {INVESTOR_CONFIGURATION.text_cta || "INVERSORES"}
              <IconDiamondSvg class="icon" />
            </a>
          ) : null}
        </div>
      </div>
    )
  }

  return realEstate.sections ? (
    <>
      <Helmet>
        {realEstate.bot_cliengo
          ? (function () {
              var ldk = document.createElement("script")
              ldk.type = "text/javascript"
              ldk.async = true
              ldk.src = realEstate.bot_cliengo
              var s = document.getElementsByTagName("script")[0]
              s.parentNode.insertBefore(ldk, s)
            })()
          : ""}
      </Helmet>
      <div className="container-fluid position-relative">
        <img
          src={ImageRight}
          className="floating-right-image"
          alt="Bellagamba"
        />
      </div>
      {/* <div
        className={`sup-footer ${
          isShulbiusPage || customShulbiusTag(development)
            ? "shulbius-sup-footer"
            : ""
        }`}
      > */}
      <div
        className={`sup-footer ${
          isShulbiusPage || isPeliculaPage || customShulbiusTag(development)
            ? "shulbius-sup-footer"
            : ""
        }`}
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 order-1">
                  <div class="item-contact row">
                    <div class="col-12">
                      <a
                        target="_blank"
                        class={
                          "hover-footer-link under-line d-none d-lg-table "
                        }
                        href={
                          "https://web.whatsapp.com/send?text=" +
                          getMessageWhatsapp(development) +
                          "&phone=+" +
                          realEstate.global_whatsapp.replaceAll("-", "")
                        }
                      >
                        Whatsapp. {realEstate?.global_whatsapp}
                      </a>
                      <a
                        target="_blank"
                        class="hover-footer-link under-line d-table d-lg-none"
                        href={
                          "https://api.whatsapp.com/send?text=" +
                          getMessageWhatsapp(development) +
                          "&phone=+" +
                          realEstate.global_whatsapp.replaceAll("-", "")
                        }
                      >
                        Whatsapp. {realEstate?.global_whatsapp}
                      </a>
                      {realEstate?.global_phone?.map(phone => (
                        <p class="under-line">Tel. {phone}</p>
                      ))}
                      {realEstate?.global_broker_id?.map(broker => (
                        <p class="under-line">Matrícula {broker}</p>
                      ))}
                      <a
                        target="_blank"
                        class="hover-footer-link under-line mb-4 mb-lg-0"
                        href={"mailto:" + realEstate?.global_email}
                      >
                        Email. {realEstate?.global_email}
                      </a>
                      <div class="social-lineal">
                        <a
                          className={`me-2 ${
                            !realEstate.social.facebook ? "d-none" : ""
                          }`}
                          href={realEstate.social.facebook}
                          target="_blank"
                        >
                          Facebook
                        </a>
                        /
                        <a
                          className={`ms-1 me-2 ${
                            !realEstate.social.instagram ? "d-none" : ""
                          }`}
                          href={realEstate.social.instagram}
                          target="_blank"
                        >
                          Instagram
                        </a>
                        /
                        <a
                          className={`ms-1 me-2 ${
                            !realEstate.social.youtube ? "d-none" : ""
                          }`}
                          href={realEstate.social.youtube}
                          target="_blank"
                        >
                          Youtube
                        </a>
                        {/* <a className={`ms-1 me-2 ${!realEstate.social.linkedin ? 'd-none' : ''}`} 
                                          href={realEstate.social.linkedin} target="_blank">LinkedIn</a>
                                      <a className={`ms-1 me-2 ${!realEstate.social.twitter ? 'd-none' : ''}`} 
                                          href={realEstate.social.twitter} target="_blank">Twitter</a> */}
                      </div>
                      <div className="d-none d-md-block">
                        {accesosPrivadosSection()}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-3 order-2">
                  {realEstate.branch_office.length > 0 &&
                    realEstate.branch_office.map((branch, index) => (
                      <div className="item-location row mb-5" key={index}>
                        <h5 className="col-12">{branch.name}</h5>
                        <div className="col-12">
                          <p className="mb-0">
                            {branch.address}, {branch.city}
                          </p>
                          <p className="mb-0">{branch.region}</p>
                          {branch.contact.phone.map(phone => (
                            <p className="mb-0">Teléfono {phone}</p>
                          ))}
                          <a
                            href={"mailto:" + branch.contact.mail}
                            className="mb-0"
                          >
                            {branch.contact.mail}
                          </a>
                          <p
                            className={
                              branch.contact.whatsapp === "" ? "d-none" : "mb-0"
                            }
                          >
                            Whatsapp +{branch.contact.whatsapp}
                          </p>
                          <p
                            className={
                              branch.broker_id === "" ? "d-none" : "mb-0"
                            }
                          >
                            Matrícula {branch.broker_id}
                          </p>
                        </div>
                      </div>
                    ))}
                  <div className="content-contact mt-auto">
                    <h4>{realEstate.sections?.footer?.title_newsletter}</h4>
                    <form onSubmit={sendForm} id="formNewsletter">
                      <div className="d-flex mb-lg-3 content-newsletter">
                        <input
                          type="email"
                          onChange={handleInputChange}
                          name="email"
                          required
                          placeholder="Ingresá tu mail"
                          className="mr-2"
                        />
                        <button
                          disabled={disabled}
                          type="submit"
                          className="submit icon-arrow-right"
                        />
                      </div>
                      {realEstate?.keys?.captcha ? (
                        <ReCAPTCHA
                          sitekey={
                            realEstate?.keys?.captcha
                              ? realEstate?.keys?.captcha
                              : 0
                          }
                          onChange={() => setDisabled(false)}
                        />
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
                <div class="col-lg-2 offset-lg-1 order-3 d-flex justify-content-lg-end align-items-start">
                  <div className="d-md-none w-100 mt-5">
                    {accesosPrivadosSection()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer
        className={`${
          isShulbiusPage || customShulbiusTag(development)
            ? "shulbius-footer"
            : ""
        }`}
      >
        <div class="container-fluid">
          <div class="row">
            <div className="col-12 copy text-center">
              Design by{" "}
              <a
                target="_blank"
                href="https://mediahaus.com.ar/"
                style={{ fontWeight: 600, color: "#00a1ff" }}
              >
                MediaHaus
              </a>{" "}
              - Powered by{" "}
              <a
                href="http://mediacore.app"
                target={"_blank"}
                style={{ fontWeight: 600, color: "#969696" }}
              >
                MediaCore
              </a>{" "}
              <br className="d-block" />
              <div className="small-copy">
                Todos los derechos reservados{" "}
                <span style={{ fontSize: ".5rem", verticalAlign: "text-top" }}>
                  ®
                </span>{" "}
                {getCurrentYear()}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <FixedFooterbar />
    </>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    filters: state.properties.totalListFilters,
    api_key: state.settings.keys.tokko,
    developments: state.developments.developments,
    development: state.developments.development,
    modules: state.settings.modules,
  }),
  null
)(Footer)
