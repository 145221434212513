import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { connect } from "react-redux"

import Header from "./header"
import Footer from "./footer"
import { useEffect } from "react"

import Loading from "./Loading"

import {CHANGE_APIKEY_ACTION,CHANGE_MAPKEY_ACTION} from '../redux/settingsDucks'
import Modules from "./modules"
import { useLocation } from "@reach/router"

const Layout = ({ dispatch, children, loading_property, loading_novelty, hideFooter, property={}, development={} }) => {
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        seo{
          title
        }
        logo
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
          google_map
        }
      }
    }
  `)

  useEffect(() => {
      dispatch(CHANGE_APIKEY_ACTION(data?.realEstate?.keys?.tokko))
      dispatch(CHANGE_MAPKEY_ACTION(data?.realEstate?.keys?.google_map))
  },[data.realEstate])

  const location = useLocation()
  const pathname = location.pathname.replaceAll('/','')
  

  return (
    <>
      <Helmet>
        <style>
        {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
          }\
        `}
        </style>
      </Helmet>
      <article>
        <Modules property={property} development={development} />
        <Header siteTitle={data.realEstate.seo?.title || `Title`} logo={data.realEstate.logo} />
        {loading_property || loading_novelty && <Loading absolute={true} />}
        <main className={"main-component " + (pathname === '' ? "isHome" : "")}>{children}</main>
        { !hideFooter 
          ? <Footer/>
          : null 
        }
      </article>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
  loading_property:state.properties.loading_property,
  loading_novelty:state.novelties.loading,
}),null)(Layout);
