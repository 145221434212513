import {Link } from 'gatsby';
import React, {useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import {connect} from 'react-redux';

import {getPropertiesAction, getTotalListFilters, REDUX_UPDATE_FILTERS} from '../redux/propertiesDucks';
import {getDevelopmentsAction} from '../redux/developmentsDucks';

import {makeItemsNav,MakeItem} from '../helpers/helper.rendering'
import {getOperationView} from '../helpers/helper.filters'

import { getNoveltiesAction } from '../redux/noveltiesDucks';
import { getActionsAction } from '../redux/actionsDucks'; 
import { getJobsAction } from '../redux/jobsDucks';




const MenuNav = ({setOpen,filters,curFilters,api_key,developments,novelties,allJobsData,dispatch}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              global_email
              social{
                facebook
                instagram
                linkedin
                youtube
                twitter
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                  footer {
                      title_contact
                      title_social
                      title_newsletter
                      title_office
                      button_contact{
                          link
                          value
                      }
                  }
              }
          }
    }`)

    // const { design: env } = realEstate


    // const { design: { icons },env } = realEstate
    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
            dispatch(getTotalListFilters())
        }
    }, [api_key])
    
    useEffect(() => {
        dispatch(getNoveltiesAction())
        dispatch(getActionsAction())
        dispatch(getJobsAction())
    },[])

    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }

    const isResultView = () => {
        if(pathname.includes('venta') || pathname.includes('alquiler')){
            return true;
        }
        return false
    }

    const checkActive = (_pathname) => {
        if((_pathname === 'venta' || _pathname === "alquiler") && pathname.includes('venta') || pathname.includes('alquiler')){
            if(curFilters.operation.includes(_pathname)){
                return true;
            }
        }
        else{
            if(pathname === (_pathname)){
                return true;
            }
        }
        return false
    }
    
    useEffect(() => {
        if(curFilters === []){
            REDUX_UPDATE_FILTERS({
                location:[],
                type:[],
                operation:'',
                environments:"",
                bedrooms: "",
                age:'',
                price:{
                    type:'',
                    priceMin:'',
                    priceMax:''
                },
                surface:{
                    type:'',
                    surfaceMin:"",
                    surfaceMax:"",
                },
                environments_types:[],
                generals:[],
                services:[],
                specials:[],
            })
        }
    })

    return realEstate.sections ? (
        <nav className="nav-content opened d-flex align-items-center">
            <div className="container-fluid">
                <div className="row">
                    <div class="col-12">
                        <ul className="nav-menu">
                            {makeItemsNav(developments,filters,novelties,allJobsData).map((element,index) => (
                                <li key={index} className={("mb-0 ")}>
                                    <a onClick={() => staticBody() + ((element.name.toLowerCase() === 'venta' || element.name.toLowerCase() === 'alquiler') ? console.log("ACTUALIZO") + dispatch(REDUX_UPDATE_FILTERS(curFilters,element.name)) + setOpen(false) + dispatch(getPropertiesAction()) : '')} href={element.link} 
                                        // className={(checkActive(pathname) && curFilters.operation.includes(element.name.toLowerCase())) ? ' active ' : (pathname.includes(element.name.toLowerCase()) ? ' active ' : '') }>
                                        className={(checkActive(element.name.toLowerCase()) ? 'active' : '')}>
                                        {/* className={(checkActive(pathname) &&  : '')}> */}
                                        {element.name}. <span>({index > 8 ? (index + 1) : `0${index + 1}` })</span>/
                                    </a>
                                </li>
                            ))}
                            {/* {console.log(novelties?.length)} */}
                        </ul>
                    </div>
                    <div class="col-12 bottom-nav">
                        <div class="container-fluid">
                            <div class="row align-item-center align-items-lg-start">
                                <div class="col-5">
                                    <div class="social-media">
                                        <a href={realEstate.social.facebook} target="_blank"
                                            class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                        <a href={realEstate.social.instagram} target="_blank"
                                            class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                        <a href={realEstate.social.youtube} target="_blank"
                                            class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                    </div>
                                </div>
                                <div class="contact-nav col-lg-4 offset-lg-2 col-7 d-block d-lg-flex justify-content-between">
                                    <a class="d-inline-block mx-auto mb-3 mb-lg-0" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                    <a class="d-none d-lg-inline-block mx-auto ml-lg-5" href="https://api.whatsapp.com/send?phone=+541134798081" target="_blank">WhatsApp. 11-3479-8081</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    ):''
}

export default connect(state => ({
    filters:state.properties.totalListFilters,
    curFilters:state.properties.filters,
    novelties:state.novelties.novelties,
    // novelties:state.novelties.grid_novelties,
    api_key:state.settings.keys.tokko,
    developments:state.developments.developments,
    allJobsData: state.jobs.jobs,
}),null)(MenuNav);