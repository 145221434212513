import React, { useEffect, useState }  from 'react';
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown';
import $ from 'jquery';
import { informCustomEvent } from '../../../helpers/helper.analytics';
import {getActionByType, getCodeFromType, getCodes} from '../../../helpers/helper.actions'

const TopAlert = ({linkTo, linkTxt, textAlert, showOn, eventDate, utmValue, small_type, small_section, name, actions, properties, property, developments, development}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    const [closed, setClosed] = useState(false);
    
    // useEffect(() => {
    //     if(checkPage()) {
    //         setTimeout(() => {
    //             document.getElementById("top-alert")
    //                 .classList.toggle("animate-show")
    //         }, 1000)
    //     }
    // }, [pathname,showOn])

    useEffect(() => {
        if(checkPage()) {
            if(showOn === "Ficha de emprendimiento" && development?.id || showOn === "Ficha de propiedad" && property?.id || (showOn !== "Ficha de emprendimiento" && showOn !== "Ficha de la propiedad")){
                setTimeout(() => {
                    if (closed === false) {
                        document.getElementById("top-alert")
                            ?.classList.toggle("animate-show");
                        $("#handler-bar").addClass( "alertOn" );
                        $(".current-filters-sumary").addClass( "alertOn" );
                        $(".main-component").addClass( "alertOn" );
                        $(".header-resultados").addClass( "alertOn" );
                    }
                }, 1000)
            }
        }
    }, [pathname,showOn,property,development])

    useEffect(() => {
        if(checkPage()) {
            if(showOn === "Ficha de emprendimiento" && development?.id || showOn === "Ficha de propiedad" && property?.id || (showOn !== "Ficha de emprendimiento" && showOn !== "Ficha de la propiedad")){
                setTimeout(() => {
                    if (closed === true) {
                        document.getElementById("top-alert")
                        ?.classList.remove("animate-show");
                        $("#handler-bar" ).removeClass( "alertOn" )
                        $(".current-filters-sumary" ).removeClass( "alertOn" )
                        $(".main-component" ).removeClass( "alertOn" )
                        $(".header-resultados" ).removeClass( "alertOn" )
                        
                    }
                },)
            }
        }
    }, [closed])

    // Check where shows
    const checkPage = () => {
        if(developments.length > 0  || properties.length > 0 ){ //Si es para una ficha en particular
            if(existAlertTopDetail(property?.id) || existAlertTopDetail(development?.id)){
                    if(properties.find(prop => prop?.toString() === property.id?.toString()) || developments.find(dev => dev?.toString() === development.id?.toString())){
                        return true
                    }
            }
            return false
        }
        else{
            if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
            if(pathname.toLowerCase().includes('emprendimientos') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento' && !existAlertTopDetail(development?.id)){console.log("true"); return true}
            if(pathname.toLowerCase().includes('propiedad') && showOn.toLowerCase() === 'ficha de la propiedad' && !existAlertTopDetail(property?.id)){console.log("true"); return true}
            if(pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta'){console.log("true"); return true}
            if(pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler'){console.log("true"); return true}
            if(pathname.toLowerCase() === 'alquiler-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){console.log("true"); return true}
            if(pathname.toLowerCase() === 'servicios' && showOn.toLowerCase() === 'tasaciones'){return true}
            if(pathname.toLowerCase() === 'blog' && showOn.toLowerCase() === 'novedades'){return true}
            return (Array.isArray(showOn)) 
                ?  showOn.toLowerCase().includes(pathname.toLowerCase())
                : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
        }
    }

    const existAlertTopDetail = (id) => {
        const squarePopUps = [...getActionByType(actions, getCodeFromType('Barra Encabezado Timer'))
        ,...getActionByType(actions, getCodeFromType('Barra Encabezado'))]
        if(id){
            for (const singleSquare of squarePopUps) {
                for (const dev of singleSquare.developments) {
                    if(dev.toString() === id.toString()){
                        return true
                    }
                }
                for (const prop of singleSquare.properties) {
                    if(prop.toString() === id.toString()){
                        return true
                    }
                }
            }
            return false
        }
        else{
            return false
        }
    }

    // Render countdown
    const rendererCtd = ({ days, hours, minutes, seconds }) =>
        <div className="counter d-flex">
            <span className="mx-2">
                <span className="date-val">{days}</span> DÍAS</span>
            <span className="me-2">
                <span className="date-val">{hours}</span> HS</span>
            <span className="me-2">
                <span className="date-val">{minutes}</span> MIN</span>
            <span className="me-2">
                <span className="date-val">{seconds}</span> SEG</span>
        </div>

    const capitalizeFirst = (text) =>
        text[0].toUpperCase() + text.slice(1, text.length)

    return checkPage() ? (
        <div id={"top-alert"} className={"alert alert-dismissible fade show " + (showOn.toLowerCase() === 'inicio' ? "isHome" : '')} role="alert">
            <div className="container-fluid">
                {/* Counter */}
                { eventDate &&
                    <div className="counter-wrapper">
                        Quedan 
                        <Countdown 
                            date={new Date(eventDate).getTime()} 
                            renderer={rendererCtd}/>
                    </div> }
                {/* Text */}
                { textAlert && 
                    <div className={`text-content my-2`}>
                        { (eventDate) ? ` ${textAlert}` : capitalizeFirst(textAlert) }
                    </div> }
                {/* Link */}
                { (linkTo && linkTxt) &&

                    <a href={linkTo + utmValue} onClick={() => informCustomEvent("PUSH_" + getCodes(type,showOn) + "_" + name)} className="btn-link" target="_blank">
                        {linkTxt}
                    </a> }

            </div>
            {/* X close button */}
            {/* <i className="icon-plus" type="button" role="button" 
                data-bs-dismiss="alert" aria-label="Close"></i> */}
            <i id="closeAlert" className="icon-plus" onClick={() => setClosed(!closed)}></i>

        </div>
    ):
    null
}

export default TopAlert
