import { Link } from "gatsby"
import React, {useState} from "react"

import NavMenu from "./menu-nav";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";
import { connect } from "react-redux";

import BellagambaOrangeSvg from "../images/svg/BELLAGAMBA_Original.svg"
import BellagambaGreySvg from "../images/svg/BELLAGAMBA_grey.svg"
import BellagambaWhiteSvg from "../images/svg/BELLAGAMBA_Original_blanco.svg"
import { getfavorites } from "../helpers/helper.favorites";

import $ from 'jquery'
import { getNoveltiesAction } from "../redux/noveltiesDucks";
import { getActionsAction } from "../redux/actionsDucks";

function Main({updaterFavorites,settings, dispatch, siteTitle, logo}) {

    const [open,setOpen] = useState(false);
    const location = useLocation()
    const [countFavorites,setCountFavorites] = useState(getfavorites("prop",settings.short_name).length)

    useEffect(() => {
        setCountFavorites(getfavorites("prop",settings.short_name).length)
        $("#count_favorites").removeClass("animate__heartBeat");
        $("#count_favorites_mobile").removeClass("animate__heartBeat");
        setTimeout(function(){
            $("#count_favorites").addClass("animate__heartBeat");
            $("#count_favorites_mobile").addClass("animate__heartBeat");
        }, 100);
    },[updaterFavorites])
    
    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
    },[location])

    useEffect(() => {
        dispatch(getNoveltiesAction())
        dispatch(getActionsAction())
    },[])


    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    const showLogo = () => {
        const ifShulbiusOpen = location.pathname.includes('/shulbius') && open;
        const ifShulbius = location.pathname.includes('/shulbius');
        const isPeliculaOpen = location.pathname.includes('/pelicula') && open;
        const isPelicula = location.pathname.includes('/pelicula');
        
        if (ifShulbiusOpen || isPeliculaOpen) {
            return <img src={"/BELLAGAMBA_Original.svg"} className="white" alt="" />
        } else if (ifShulbius || isPelicula) {
            return <img src={"/BELLAGAMBA_Original_blanco.svg"} className="shulbius"/>
        } else if (open) {
            return <img src="/BELLAGAMBA_grey.svg" className="white"/>
        } else {
            return <img src="/BELLAGAMBA_Original.svg" className="color"/>
        }
    }

    return(
    <header id="header" className={"blur " + (open ? 'opened' : '')}>
        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
        <nav className="brand">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-6">
                        <Link href={"/"} className="logo">
                            { showLogo() }
                        </Link>
                    </div>
                    <div className="col-6 text-right d-flex align-items-center justify-content-end">
                        {/* <Link className="me-4 icon-favorites d-flex align-items-center justify-content-center" to={"/favoritos"}><i className={"icon-like " + (location.pathname.includes("favoritos") ? 'active' : '')}></i> </Link> */}
                        <Link onClick={() => (countFavorites === 0 ? toast.error("No tienes Propiedades favoritas seleccionadas.") : '')}  className="me-0 icon-favorites d-lg-flex align-items-center justify-content-center " to={countFavorites === 0 ? location.pathname : "/favoritos"} >
                            <span id="count_favorites me-2">{countFavorites}</span>
                            <i className={ "ms-2" + (location.pathname.includes("favoritos") ? " icon-favoritos-heart-solid" : " icon-favoritos-heart") + (location.pathname.includes("favoritos") ? " active " : "") } ></i>{" "}
                        </Link>

                        <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                            <div className="d-flex align-items-center"> 
                                <span className="status_nav d-none d-lg-inline ms-4">{open ? "CERRAR" : "MENÚ"} </span> 
                                <span className="ms-4"><i></i><i></i><i></i></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
        <NavMenu setOpen={setOpen} />
  </header>
)}

export default connect(state => ({
    settings: state.settings,
    updaterFavorites:state.properties.updaterFavorites
  }),null)(Main);
  