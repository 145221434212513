import React from 'react'
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import WhatsappSvg from '../images/svg/whatsapp.svg'

const FixedFooterbar = () => {

    const location = useLocation()

    const wpBtnDesktop = () => {
        const phoneNum = location.pathname.includes('/shulbius') ? '+541152210311' : '+541134798081'
        return  <a target="_blank" href={`https://web.whatsapp.com/send?text=¡Hola!&phone=${phoneNum}`} 
                    class="contact-btn d-none d-lg-flex">
                        <WhatsappSvg/>
                </a>
    }
    
    const wpBtnMobile = () => {
        const phoneNum = location.pathname.includes('/shulbius') ? '+541152210311' : '+541134798081'
        return  <a target="_blank" href={`https://api.whatsapp.com/send?phone=${phoneNum}`} 
                    class="contact-btn d-flex d-lg-none">
                        <WhatsappSvg/>
                </a>
    }

    return (
        <div class={"fixed-bar position-fixed " + (location.pathname === '/' ? 'on-home' : '') + (location.pathname.includes('venta') || location.pathname.includes('alquiler') ? 'd-none' : '')}>
            {wpBtnDesktop()}
            {wpBtnMobile()}
        </div>
    )
}

export default FixedFooterbar