import { useLocation } from "@reach/router"
import * as React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import AlertTop from './Modules/MediaPush/AlertTop'
import FlapButton from './Modules/MediaPush/FlapButton'
import CounterPopup from "./Modules/MediaPush/CounterPopup"
import SquarePop from './Modules/MediaPush/SquarePop'
import {getActionByType} from '../helpers/helper.actions'


const Modules = ({actions, property, development}) => {

  const { pathname } = useLocation()
  const isViewDevelopment = pathname?.split("/")[1]?.includes("emprendimientos");
  const isViewProperty = pathname?.split("/")[1]?.includes("propiedad");

  return (
    <>
      {getActionByType(actions,'Solapa Flotante').map((action => (
        <FlapButton
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments} 
          name={action.name}
          type={action.type}
          section={action.section}
          small_type={action.small_type}
          small_section={action.small_section}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_limited}
          alignment={action.alignment}
          showOn={action.section}/>
      )))}
      {getActionByType(actions,'PopUp Timer').map((action => (
        <CounterPopup
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments} 
          linkTo={action.texts.text_URL} 
          linkTxt={action.texts.text_button} 
          title={action.title}
          showOn={action.section}
          band={action.texts.text_band} 
          text={action.texts.text}
          utmValue={action.texts.text_utm}
          name={action.name}
          type={action.type}
          small_type={action.small_type}
          small_section={action.small_section}
          eventDate={action.date_start}/> 
      )))}
      {getActionByType(actions,'PopUp Vertical').map((action => (
        <SquarePop
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments}
          align={action.alignment} 
          flat={false} 
          linkTo={action.url_destiny}
          image={action.file}
          utmValue={action.text_utm}
          name={action.name}
          type={action.type}
          small_type={action.small_type}
          small_section={action.small_section}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'PopUp Cuadrado').map((action => (
        <SquarePop
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments}
          align={""} 
          flat={false} 
          linkTo={action.url_destiny}
          image={action.file}
          name={action.name}
          type={action.type}
          small_type={action.small_type}
          small_section={action.small_section}
          utmValue={action.text_utm}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'PopUp Panoramico').map((action => (
        <SquarePop
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments}
          align={""} 
          flat={true} 
          linkTo={action.url_destiny}
          image={action.file}
          type={action.type}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          utmValue={action.text_utm}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'Barra Encabezado Timer').map((action => (
        <AlertTop
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments} 
          linkTo={action.texts.text_URL}
          name={action.name}
          small_type={action.small_type}
          type={action.type}
          small_section={action.small_section}
          linkTxt={action.texts.text_button}
          textAlert={action.texts?.text}
          showOn={action.section}
          utmValue={action.texts.text_utm}
          eventDate={action.date_start}/>
      )))}
      {getActionByType(actions,'Barra Encabezado').map((action => (
        <AlertTop
          actions={actions} 
          property={isViewProperty ? property : undefined}
          properties={action.properties}
          development={isViewDevelopment ? development : undefined} 
          developments={action.developments} 
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_button}
          textAlert={action.texts?.text}
          utmValue={action.texts.text_utm}
          name={action.name}
          small_type={action.small_type}
          type={action.type}
          small_section={action.small_section}
          showOn={action.section}/>
      )))}
    </>
  )
}

export default connect(state => ({
  actions:state.actions.actions
}),null)(Modules);